@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background-color: var(--extBlack);
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  padding: 16px 0;
  position: relative;
  z-index: 0;

  &.disabled {
    cursor: default;
  }

  .gradient {
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.8s;
    z-index: -2;
  }

  .icon {
    animation: icon-animation 0.8s;
    position: absolute;
    right: 20px;
    top: -58px;
    transition: 0.8s;
    z-index: -1;
  }

  .top {
    padding: 0 16px;
  }

  &.open {
    cursor: default;

    .top {
      cursor: pointer;
    }

    .gradient {
      height: 240px;
      transition: 0.8s;
    }

    .icon {
      transform: translateY(40px);
      transition: 0.8s;
    }
  }

  &.low {
    .gradient {
      background: radial-gradient(70% 47.01% at 100% 23.6%,
      #0F7952 24.66%,
      #202124 100%),
        #202124;
    }

    .risk {
      color: var(--extPrimary);
    }
  }

  &.middle {
    .gradient {
      background: radial-gradient(70% 51.49% at 104.48% 23.6%,
      #786511 22.93%,
      #202124 100%),
        #202124;
    }

    .risk {
      color: var(--extWarning);
    }
  }

  &.critical {
    .gradient {
      background: radial-gradient(70% 51.09% at 104.08% 23.6%,
      #742B27 22.93%,
      #202124 100%),
        #202124;
    }

    .risk {
      color: var(--extError);
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .count {
      @include r2(var(--extLight));
    }

    .arrow {
      transition: 0.3s;

      path {
        fill: var(--extLight);
      }
    }
  }

  &.open {
    .header {
      .arrow {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }

  .risk {
    @include h1-d;
    margin-top: 5px;
  }

  .description {
    @include r1-b(var(--extLight));
    margin-top: 4px;
    padding: 0 16px;
  }
}

@keyframes icon-animation {
  0% {
    opacity: 0;
    top: -120px;
  }

  70% {
    top: -48px;
  }

  100% {
    opacity: 1;
    top: -58px;
  }
}

.label {
  @include r2(var(--extLight));
  background: rgba(255, 255, 255, 0.15);
  border-radius: 32px;
  padding: 0 8px;
}

@include dark-theme {
  .wrap {
    background-color: var(--extDark);

    &.low {
      .gradient {
        background: radial-gradient(70% 47.01% at 100% 23.6%,
        #0F7952 24.66%,
        #000 100%),
          #000;
      }
    }

    &.middle {
      .gradient {
        background: radial-gradient(70% 51.49% at 104.48% 23.6%,
        #786511 24.66%,
        #000 100%),
          #000;
      }
    }

    &.critical {
      .gradient {
        background: radial-gradient(70% 51.49% at 104.48% 23.6%,
        #742B27 24.66%,
        #000 100%),
          #000;
      }
    }
  }
}
