@import 'shared/assets/scss/helpers/typography';

.wrapper {
  align-items: center;
  background: var(--extWhite);
  display: flex;
  justify-content: center;
  left: 0;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
