@import "shared/assets/scss/helpers/typography";
@import "shared/assets/scss/helpers/mixins";

.wrap {
  background: linear-gradient(
    299.8deg,
    #f7f7f7 52%,
    rgba(248, 241, 222, 0.899944) 81.73%,
    rgba(255, 193, 48, 0.2) 100%
  );
  border-radius: 16px;
  margin-bottom: 8px;
  overflow: hidden;
  padding: 10px 16px 16px;
  position: relative;

  .top {
    @include r1;
    margin-bottom: 8px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .title {
    margin-top: 6px;
  }

  .description {
    @include r3(var(--extGray5));
    margin-top: 8px;
  }

  .closeBtn {
    align-items: center;
    background: var(--extWhite);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: 6px;
    position: absolute;
    right: 12px;
    top: 12px;

    &:hover {
      opacity: 0.8;
      transition: 0.2s;
    }

    svg {
      height: 14px;
      width: 14px;

      path {
        stroke: var(--extGray3);
      }
    }
  }
}

@include dark-theme {
  .wrap {
    background: linear-gradient(299.8deg, #3D3D3D 64.3%, rgba(109, 94, 58, 0.803899) 84.04%, rgba(255, 193, 48, 0.2) 100%);
  }
}
