.light-ext {
  --ext1: #FFF;
  --ext2: #00D180;
  --ext3: #00330E;
  --ext4: #008A26;
  --ext5: #FF2843;
  --ext6: #FFECCF;
  --ext7: #FFBB52;
  --ext8: #F5F6F8;
  --ext9: #E3E6EB;
  --ext10: #515359;
  --ext11: #333;
  --ext12: #000;
  --ext13: #F5F6F8;
  --ext14: #F2F2F2;
  --ext15: #C2C2C2;
  --ext16: #D2D3D4;
  --ext17: #252932;
  --ext18: #79848E;
  --ext19: #0A0A0A;
  --ext20: #FFF;
  --ext21: #FFF;
  --ext22: #F5F6F8;
  --ext23: #C5C5C8;
  --ext24: #EAECF0;
  --ext25: #F5F6F8;
  --ext26: rgba(0, 0, 0, 0.2);
  --ext27: rgba(0, 0, 0, 0.1);
  --ext28: #79848E;
  --ext29: #79848E;
  --ext30: #2C3241;
  --ext31: #808080;
  --extWhite: #FFF;
  --extBlack: #202124;
  --extDark: #000;
  --extLight: #FFF;
  --extGray1: #F7F7F7;
  --extGray2: #E6E6E6;
  --extGray3: #CCC;
  --extGray4: #999;
  --extGray5: #808080;
  --extGray6: #666;
  --extGray7: #3D3D3D;
  --extGray8: #292929;
  --extGray9: #A2A2A2;
  --extGray10: #F7F7F7;
  --extPrimary: #00D180;
  --extPrimary2: #00BD74;
  --extPrimary3: #00A867;
  --extError: #E45C51;
  --extError2: #D13F33;
  --extError3: #B6392F;
  --extWarning: #F5C300;
  --extWarning2: #E5B000;
  --extWarning3: #D69A00;
  --extBlue: #70A3F3;
  --extDarkBlue: #4C88F0;
}

.dark-ext {
  --ext1: #2C3241;
  --ext2: #00D180;
  --ext3: #00330E;
  --ext4: #3FAF48;
  --ext5: #FF2843;
  --ext6: #485365;
  --ext7: #FFF;
  --ext8: #1B1E26;
  --ext9: #1B1E26;
  --ext10: #A4A9B4;
  --ext11: #BBC0CB;
  --ext12: #FFF;
  --ext13: rgba(255, 255, 255, 0.08);
  --ext14: #373C4B;
  --ext15: #5F636F;
  --ext16: #2B303B;
  --ext17: #FFF;
  --ext18: #97A2AB;
  --ext19: #FFF;
  --ext20: #3D4250;
  --ext21: #000;
  --ext22: #D0D3D9;
  --ext23: #84878E;
  --ext24: #C3C7CF;
  --ext25: #3D4250;
  --ext26: rgba(255, 255, 255, 0.2);
  --ext27: rgba(255, 255, 255, 0.1);
  --ext28: rgba(255, 255, 255, 0.7);
  --ext29: #BBC0CB;
  --ext30: #FFF;
  --ext31: #FFF;
  --extWhite: #202124;
  --extBlack: #FFF;
  --extDark: #000;
  --extLight: #FFF;
  --extGray1: #F7F7F7;
  --extGray2: #E6E6E6;
  --extGray3: #CCC;
  --extGray4: #999;
  --extGray5: #999;
  --extGray6: #666;
  --extGray7: #3D3D3D;
  --extGray8: #292929;
  --extGray9: #A2A2A2;
  --extGray10: #F7F7F7;
  --extPrimary: #00D180;
  --extPrimary2: #00BD74;
  --extPrimary3: #00A867;
  --extError: #E45C51;
  --extError2: #D13F33;
  --extError3: #B6392F;
  --extWarning: #F5C300;
  --extWarning2: #E5B000;
  --extWarning3: #D69A00;
  --extBlue: #70A3F3;
  --expDarkBlue: #4C88F0;
  background-color: #202124;
}
