@import 'shared/assets/scss/helpers/typography';

.wrapper {
  align-items: center;
  background: var(--extWhite);
  display: flex;
  justify-content: center;
  left: 0;
  padding: 14px 16px 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  &.withChildren {
    justify-content: space-between;
  }
}
