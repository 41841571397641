@import 'shared/assets/scss/helpers/mixins';

.wrap {
  @include r3;
  background: #F3F3F3;
  border-radius: 4px;
  border-radius: 12px;
  color: var(--extBlack);
  padding: 8px 12px;
  position: relative;

  .tail {
    left: 0;
    position: absolute;
    top: -6px;
  }

  .main {
    align-items: center;
    display: flex;
    gap: 8px;

    img {
      height: 32px;
      width: 32px;
    }
  }
}

@include dark-theme {
  .wrap {
    background-color: var(--extGray7);
    color: var(--extGray2);
  }

  .tail {
    path {
      fill: var(--extGray7);
    }
  }
}
