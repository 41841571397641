@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-bottom: 8px;

  .label {
    @include r3(var(--extGray5));
    align-items: center;
    display: flex;
    gap: 4px;

    .icon {
      .barIcon {
        background: var(--extGray2);
        border-radius: 1px;
        height: 8px;
        width: 8px;
      }
    }
  }
}

@include dark-theme {
  .wrap {
    .label {
      .barIcon {
        background: var(--extGray6);
      }
    }
  }
}
