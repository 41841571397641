@import 'shared/assets/scss/helpers/mixins';

.wrap {
  border-radius: 8px;
}

.row {
  align-items: center;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  line-height: 21px;
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .name {
    @include h6;
  }

  .data {
    display: flex;
    justify-content: space-between;

    &.empty {
      align-items: center;
    }

    .tokenImage,
    .nftImageWrap,
    .emptyStub {
      margin-right: 8px;
    }

    .name,
    .collectionName {
      span {
        @include ellipsis-text();
        display: block;
        max-width: 170px;
        white-space: nowrap;
      }
    }

    .name {
      @include h6;
      align-items: center;
      color: var(--extBlack);
      column-gap: 4px;
      display: flex;

      &.link {
        @include link;
        font-size: 15px;
        line-height: 21px;
      }
    }

    .collectionName {
      @include r3;
      color: var(--extGray5);
      margin-top: 2px;
    }

    .label {
      @include r3(var(--extGray5));
      background: var(--extWhite);
      border: 0.5px solid var(--extGray2);
      border-radius: 4px;
      margin-top: 4px;
      padding: 2px 5px;
    }
  }

  .value {
    @include r1;
    color: var(--extBlack);

    .amount {
      font-weight: 700;
      text-align: right;
    }

    .usdPrice {
      @include r3;
      color: var(--extGray5);
      margin-top: 2px;
      text-align: right;
    }
  }

  &.nft {
    display: block;

    .data {
      align-items: flex-start;
      justify-content: space-between;
    }

    .nftInfo {
      display: flex;
    }

    .nftsCount {
      @include r1-b(var(--extPrimary));
      margin-top: 4px;
      min-width: 48px;
    }

    .nftImageWrap {
      position: relative;
      height: 40px;

      .nftImage {
        border-radius: 8px;
        height: 40px;
        width: 40px;
      }
    }

    .marketplaceIcon {
      background-color: var(--extWhite);
      border: 1.5px solid var(--extWhite);
      border-radius: 50%;
      bottom: 0;
      height: 18px;
      position: absolute;
      right: -4px;
      width: 18px;
    }

    .estimatedPrice {
      align-items: center;
      display: flex;
      gap: 4px;
      justify-content: space-between;
      width: fit-content;

      .price {
        color: var(--extPrimary2);
      }
    }
  }

  &.token {
    display: block;

    .tokenImage {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }

    .value {
      max-width: 32%;
      word-break: break-word;
    }
  }

  .nameWrap {
    .count {
      background: var(--extGray2);
      border-radius: 24px;
      color: var(--extBlack);
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      margin-left: 4px;
      min-width: fit-content;
      padding: 2px 4.5px;

      span {
        text-decoration: none;
      }
    }
  }

  .tokenInfo {
    display: flex;
    min-width: 30%;

    .tokenData {
      margin: auto 0;
    }

    .popup {
      max-width: 220px;
    }

    .description {
      @include r3;
      color: var(--extGray5);
      margin-top: 2px;
    }
  }

  .tokenTaxes {
    align-items: center;
    display: flex;
    gap: 4px;
    margin-left: 48px;
    margin-top: 2px;

    .tokenTax {
      @include r3;
      align-items: center;
      border: 0.5px solid var(--extGray2);
      border-radius: 4px;
      display: flex;
      gap: 4px;
      justify-content: space-between;
      padding: 2px 5px;

      .taxName {
        color: var(--extGray5);
      }

      .taxPercent {
        word-break: break-all;

        &.dangerPercent {
          color: var(--extError);
          min-width: fit-content;
        }
      }
    }
  }

  .verifiedIcon {
    height: 10px;
    width: 10px;
  }

  .warning {
    margin-top: 8px;
  }
}

.separator {
  border-bottom: 1px solid var(--extGray2);
  margin: 16px 0;
  position: relative;

  .icon {
    align-items: center;
    background: var(--extWhite);
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: -12px;
    transform: translate(-50%);
    width: 24px;

    img {
      height: 16px;
      min-width: 16px;
      width: 16px;
    }
  }
}

.top {
  align-items: center;
  display: flex;
}

.washTrading {
  align-items: center;
  background: #FCEFEE;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  margin-top: 4px;
  padding: 5px;
  width: fit-content;

  span {
    color: #D13F33;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }
}

.washTradingPopup {
  @include r2;
  color: var(--extGray5);
  min-width: 216px;
}

.nftSkeleton {
  height: 48px;
}

@include dark-theme {
  .separator {
    border-bottom-color: var(--extGray7);

    .icon {
      svg {
        stroke: var(--extGray3);
      }
    }
  }

  .tokenTaxes {
    .tokenTax {
      border: 0.5px solid var(--extGray7);
    }
  }

  .wrap {
    .row {
      .label {
        border-color: var(--extGray7);
      }
    }
  }
}

.honeypotIcon {
  height: 10px;
  width: 10px;
}
