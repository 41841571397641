@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px;

  .title {
    @include h6;
    margin-bottom: 8px;
  }

  .info {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;

    .item {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1 0 0;
      flex-direction: column;

      .value {
        @include r2-b;
      }

      &.infinity {
        color: var(--extError);
      }
    }
  }

  .label {
    @include r3(var(--extGray5));
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);
  }
}
