@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px;

  .title {
    @include h6;
    margin-bottom: 8px;
  }

  .info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 8px;

    .item {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1 0 0;
      flex-direction: column;

      .value {
        @include r2-b;
      }
    }
  }

  .label {
    @include r3(var(--extGray5));
  }

  .mintedWrap {
    .progressBar {
      background: var(--extGray2);
      border-radius: 4px;
      height: 6px;
      margin: 8px 0;
      position: relative;
      width: 100%;
    }

    .progress {
      background: var(--extGray4);
      border-radius: 4px 0 0 4px;
      height: 6px;
      left: 0;
      position: absolute;
      top: 0;
    }

    .count {
      @include r2();

      .gray {
        color: var(--extGray5);
      }
    }
  }

  .description {
    @include r3;
    margin: 0 0 16px;
    overflow: hidden;
  }

  .collectionLink {
    @include link;
    align-items: center;
    color: var(--extBlack);
    column-gap: 4px;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    overflow: hidden;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);
  }

  .collectionLink {
    svg {
      path {
        stroke: var(--extLight);
      }
    }
  }
}
