@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px 16px;

  &.disabledCollapse {
    .relatedTab {
      pointer-events: none;
    }

    .openBtn {
      pointer-events: none;
    }
  }

  &.withData {
    .top {
      cursor: pointer;
    }
  }

  .top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    &.clickable {
      cursor: pointer;

      &:hover {
        .arrowIcon {
          path {
            fill: var(--extBlack);
          }
        }
      }
    }

    .titleWrap {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .title {
        @include h6;
      }

      .count {
        @include r3(var(--extWhite));
        align-items: center;
        background-color: var(--extBlack);
        border-radius: 50%;
        display: flex;
        height: 16px;
        justify-content: center;
        width: 16px;
      }

      .label {
        @include r3;
        border-radius: 24px;
        padding: 2px 6px 1px;

        &.noData {
          background-color: var(--extGray1);
          color: var(--extGray5);
        }

        &.notFound {
          background: rgba(0, 209, 128, 0.07);
          color: var(--extPrimary3);
        }
      }
    }

    .rightContentWrap {
      align-items: center;
      display: flex;
      gap: 16px;

      .tabs {
        align-items: center;
        background: var(--extGray1);
        border: 0.5px solid var(--extGray2);
        border-radius: 4px;
        display: flex;
        overflow: hidden;
        padding: 1px;

        .tab {
          @include r3(var(--extGray5));
          align-items: flex-start;
          display: flex;
          gap: 2px;
          padding: 2px 6px;
          transition: 0.2s;

          .tabName {
            max-width: 76px;
          }

          &.active {
            background-color: var(--extWhite);
            border-radius: 3px;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
            color: var(--extBlack);
          }

          &:not(.active) {
            &:hover {
              color: var(--extBlack);
              transition: 0.2s;
            }
          }

          .tabCount {
            color: var(--extGray5);
            font-size: 9px;
            line-height: 9px;
          }
        }
      }
    }

    .arrowIcon {
      height: 20px;
      width: 20px;
    }
  }

  .openBtn {
    align-items: center;
    color: var(--extBlack);
    display: flex;
    font-size: 11px;
    font-weight: 600;
    gap: 4px;
    line-height: 16px;
    margin-top: 8px;

    &:hover {
      color: var(--extGray5);
      transition: 0.2s;
    }

    .btnArrowIcon {
      height: 12px;
      transition: 0.2s;
      width: 12px;

      path {
        fill: var(--extBlack);
      }
    }
  }

  &.open {
    .arrowIcon {
      transform: rotate(180deg);
      transition: 0.2s;
    }

    .btnArrowIcon {
      transform: rotate(180deg);
      transition: 0.2s;
    }
  }

  .description {
    @include r3(var(--extGray5));
    margin-top: 4px;
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);

    .titleWrap {
      .label {
        &.noData {
          background-color: var(--extGray7);
          color: var(--extGray4);
        }
      }
    }

    .rightContentWrap {
      .tabs {
        background: var(--extGray7);
        border: 0.5px solid var(--extGray7);
      }
    }
  }
}
