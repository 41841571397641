@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/variables';
@import 'shared/assets/scss/helpers/mixins';

.main {
  background-color: var(--extWhite);
  box-sizing: border-box;
  padding: 0 16px 16px;
  width: $extensionWidth;

  .block {
    @include block;
    margin-top: 8px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .title {
      @include r1(var(--extGray5));
      margin-bottom: 8px;
    }
  }
}

@include dark-theme {
  .block {
    border-color: var(--extGray7);
  }
}
