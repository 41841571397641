@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background-color: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  margin-top: 8px;
  overflow: hidden;

  .row {
    @include r3;
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;

    &:first-child {
      padding-top: 18px;
    }

    &:last-child {
      padding-bottom: 18px;
    }

    &:not(:first-child) {
      &::after {
        background-color: var(--extGray2);
        content: '';
        height: 0.5px;
        left: 16px;
        position: absolute;
        right: 16px;
        top: 0;
      }
    }

    &.button {
      width: 100%;

      &:hover {
        background-color: var(--extGray1);
        transition: 0.2s;
      }
    }

    .rightIcon {
      height: 12px;
      min-width: 12px;
      width: 12px;

      path {
        stroke: var(--extBlack);
      }
    }

    .data {
      align-items: center;
      display: flex;
      gap: 8px;
    }
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);
  }

  .row {
    &:not(:first-child) {
      &::after {
        background-color: var(--extGray7);
      }
    }

    &.button {
      &:hover {
        background-color: var(--extGray7);
      }
    }
  }
}
