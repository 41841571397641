@import 'shared/assets/scss/helpers/typography';

.wrap {
  background: var(--extWhite);
}

.collectionName {
  @include r3;
  color: var(--extGray5);
}

.singleNft {
  align-items: center;
  display: flex;
  gap: 8px;

  .nameWrap {
    padding: 4.5px 0;
    word-break: break-word;
  }

  .top {
    @include r1-b();
    display: flex;
    margin-bottom: 2px;
  }
}

.nftsList {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  .price {
    @include h6;
  }

  .list {
    align-items: center;
    display: flex;

    .item {
      border: 1px solid var(--extWhite);
      margin-left: -20px;
      z-index: 2;

      &.firstImage {
        margin-left: 0;
      }
    }

    .count {
      @include r1;
      align-items: center;
      background-color: var(--extGray1);
      border-radius: 7.5px;
      display: flex;
      height: 44px;
      justify-content: center;
      width: 44px;
    }
  }

  .collectionName {
    margin-top: 8px;
  }
}

.price {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  margin-left: auto;
  text-align: right;
}

.eth {
  @include r1();
  font-weight: 700;
}

.usd {
  @include r3();
  color: var(--extGray5);
  line-height: 16px;
}
