@import 'shared/assets/scss/helpers/typography';

.pairs {
  .pair {
    @include r1;
    align-items: center;
    background-color: var(--extGray1);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-top: 8px;
    padding: 7.5px 10px 7.5px 11px;
    width: 100%;

    &:hover {
      opacity: 0.8;
      transition: 0.2s;
    }

    .text {
      color: var(--extGray5);
    }

    .pairsCount {
      align-items: center;
      display: flex;
      gap: 4px;
    }
  }
}
