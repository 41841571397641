@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/shared/assets/fonts/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/shared/assets/fonts/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/shared/assets/fonts/Inter-Bold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('/shared/assets/fonts/Inter-ExtraBold.woff2') format('woff2');
}

