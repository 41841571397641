@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  @include r1;
  align-items: center;
  display: flex;
  gap: 8px;

  .name {
    @include r1-b;
    color: var(--extBlack);
    margin-bottom: 2px;
    word-break: break-word;
  }

  .address {
    outline: 0;
    padding: 0;
    word-break: break-all;

    a {
      @include r3;
      color: var(--extGray5);
    }
  }

  .icon {
    background-color: var(--extGray1);
    border-radius: 50%;

    &.customIcon {
      padding: 8px;
    }
  }

  .address {
    a {
      font-size: 10px;
    }
  }
}

.message {
  @include r3;
  margin-top: 16px;
}

.labels {
  align-items: center;
  column-gap: 4px;
  display: flex;
  margin-top: 2px;
}

@include dark-theme {
  .wrap {
    .labels {
      .label {
        border-color: var(--extGray7);
      }
    }
  }
}
