@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrapper {
  display: flex;
  justify-content: center;

  :global {
    .ui.pagination.menu {
      background-color: var(--extWhite);
      border: 0;
      box-shadow: none;
      gap: 8px;
      min-height: 32px;

      @include media-breakpoint-down($mobileWidth) {
        width: 100%;
      }

      @include media-breakpoint-down($mobileWidth) {
        .icon.item:first-child {
          margin-right: auto;
        }

        .icon.item:last-child {
          margin-left: auto;
        }
      }

      .item {
        @include r2-b(var(--extBlack));
        align-items: center;
        border-radius: 32px;
        display: flex;
        font-family: 'Inter', sans-serif;
        justify-content: center;
        max-height: 32px;
        max-width: 32px;
        min-width: 32px;
        padding: 0;
        transition: background-color 0.3s;

        &::before {
          width: 0;
        }

        &:last-child,
        &:first-child {
          border: 1px solid var(--extGray2);
          border-radius: 32px;
        }

        &:hover {
          background-color: var(--extGray1);
        }

        &:active {
          background-color: var(--extGray2);
        }

        &.disabled {
          border: 1px solid var(--extGray1);
          pointer-events: none;

          path {
            fill: var(--extGray2);
          }
        }
      }

      .active.item {
        background-color: var(--extGray1);
        padding: 0;
      }
    }
  }

  .icon {
    height: 16px;
    transform: rotate(90deg);
    width: 16px;

    path {
      fill: var(--extBlack);
    }

    &.rightIcon {
      transform: rotate(270deg);
    }

    &.disabled {
      path {
        fill: var(--extGray2);
      }
    }
  }

  &.isFirstPage {
    :global {
      .ui.pagination.menu {
        .item {
          &:first-child {
            border: 1px solid var(--extGray1);
            pointer-events: none;
          }
        }
      }
    }
  }

  &.isLastPage {
    :global {
      .ui.pagination.menu {
        .item {
          &:last-child {
            border: 1px solid var(--extGray1);
            pointer-events: none;
          }
        }
      }
    }
  }
}

@include dark-theme {
  .wrapper {
    :global {
      .ui.pagination.menu {
        .active.item {
          background-color: var(--extGray7);
          padding: 0;
        }

        .item {
          &:hover {
            background-color: var(--extGray6);
          }

          &.disabled {
            border: 1px solid var(--extGray7);
            color: var(--extGray7) !important;
            pointer-events: none;
  
            path {
              fill: var(--extGray7);
            }
          }
        }
      }
    }

    &.isFirstPage {
      :global {
        .ui.pagination.menu {
          .item {
            &:first-child {
              border: 1px solid var(--extGray7);

              path {
                fill: var(--extGray7);
              }
            }
          }
        }
      }
    }

    &.isLastPage {
      :global {
        .ui.pagination.menu {
          .item {
            &:last-child {
              border: 1px solid var(--extGray7);

              path {
                fill: var(--extGray7);
              }
            }
          }
        }
      }
    }
  }
}
