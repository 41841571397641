@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background-color: var(--extWhite);
  padding: 0 16px 16px;

  .block {
    border: 1px solid var(--extGray2);
    border-radius: 16px;
    margin-top: 8px;
    padding: 12px 16px 16px;

    .title {
      @include r3(var(--extGray5));
      margin-bottom: 8px;
    }
  }
}

@include dark-theme {
  .wrap {
    .block {
      border: 1px solid var(--extGray7);
    }
  }
}
