@import 'shared/assets/scss/helpers/typography';

.wrap {
  align-items: center;
  display: flex;

  .image {
    margin-right: 8px;
  }

  .name {
    @include h6;
  }

  .icon {
    height: 10px;
    margin-left: 4px;
    width: 10px;
  }
}
