.wrap {
  border-radius: 16px;
  height: 4px;
  margin: 6px 0;
  position: relative;
  width: 30px;

  .circle {
    align-items: center;
    background-color: inherit;
    border-radius: 50%;
    display: flex;
    height: 16px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: -6px;
    width: 16px;

    svg {
      path {
        fill: var(--extWhite);
      }
    }
  }

  &.low {
    background-color: var(--extPrimary);

    .circle {
      left: 0;
    }

    &.increase {
      .circle {
        left: 2.5px;
      }
    }
  }

  &.middle {
    background-color: var(--extWarning);

    .circle {
      left: 5px;
    }

    &.increase {
      .circle {
        left: auto;
        right: 5px;
      }
    }
  }

  &.critical {
    background-color: var(--extError);

    .circle {
      left: auto;
      right: 2.5px;
    }

    &.increase {
      .circle {
        left: auto;
        right: 0;
      }
    }
  }

  &.disabled {
    background-color: var(--extGray3);
  }
}
