@import 'shared/assets/scss/helpers/mixins';
@import 'shared/assets/scss/helpers/variables';

$headerHeightAndBottomPadding: 60px;

.wrap {
  @include scroll();
  background-color: var(--extWhite);
  max-height: calc(100vh - 200px - #{$headerHeightAndBottomPadding});
  overflow-y: auto;
  padding-bottom: $headerHeightAndBottomPadding;
  padding-top: $headerHeight;
}

.buttons {
  bottom: 0;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  position: absolute;
  right: 0;
  z-index: 3;
}
