@import 'shared/assets/scss/helpers/mixins';
@import 'shared/assets/scss/helpers/typography';

.root {
  :global {
    .ui.table {
      background-color: var(--extWhite);
      border: 0;
      border-radius: 0;

      @include media-breakpoint-down($mobileWidth) {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .ui.table thead th {
      @include r2-b(var(--extGray4));
      background-color: var(--extWhite);
      border: 0;
      border-bottom: 1px var(--extGray2) solid;
    }

    .ui.table tfoot th {
      padding: 0;
    }
  }

  .row {
    position: relative;

    .cell {
      @include r1(var(--extBlack));
      border-bottom: 1px var(--extGray2) solid;
      border-top: 0;
      padding: 17.5px 8px 17.5px 6px !important;

      &.pointer {
        cursor: pointer;
      }
    }

    &:last-child {
      .cell {
        border-bottom: 0;
      }
    }

    &:hover:not(:has(:global(.risk-label):hover)) {
      background-color: var(--extGray1);

      .cell {
        &:last-child {
          &::before {
            background-color: var(--extGray1);
            border-radius: 8px 0 0 8px;
            content: '';
            height: 100%;
            left: -12px;
            position: absolute;
            top: 0;
            width: 12px;
          }
        }

        &:last-child {
          &::after {
            background-color: var(--extGray1);
            border-radius: 0 8px 8px 0;
            content: '';
            height: 100%;
            position: absolute;
            right: -12px;
            top: 0;
            width: 12px;
          }
        }
      }
    }
  }


  .headerCell {
    font-weight: 400 !important;
    padding: 7px 0 15px 6px !important;
    text-transform: capitalize !important;

    &.cellRight {
      justify-content: flex-end;
      text-align: right;
    }

    .alignLeft {
      justify-content: flex-start;
      text-align: left;
    }
  }

  .tableFooter {
    height: 48px;
    padding: 0;
  }
}

@include dark-theme {
  .root {
    :global {
      .ui.table thead th {
        border-bottom: 1px var(--extGray7) solid;
      }
    }

    .row {
      &:hover:not(:has(:global(.risk-label):hover)) {
        background-color: var(--extGray7);

        .cell {
          &:last-child {
            &::before {
              background-color: var(--extGray7);
            }
          }
  
          &:last-child {
            &::after {
              background-color: var(--extGray7);
            }
          }
        }
      }  

      .cell {
        border-bottom: 1px var(--extGray7) solid;
      }

      &:last-child {
        .cell {
          border-bottom: 0;
        }
      }
    }
  }
}
