@import 'shared/assets/scss/helpers/typography';

.detect {
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  padding: 4px 16px;
  position: relative;
  transition: 0.4s;

  &.open {
    &::after {
      background-color: var(--extGray6);
      bottom: 0;
      content: '';
      height: 0.5px;
      left: 16px;
      position: absolute;
      right: 16px;
    }
  }

  &.hasClick {
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      transition: 0.2s;
    }
  }

  .header {
    align-items: center;
    display: flex;
    gap: 4px;

    .title {
      @include r1-b(var(--extLight));
    }
  }

  .description {
    @include r2(var(--extLight));
    opacity: 0;
  }

  &.open {
    padding: 16px;

    .description {
      margin-top: 4px;
      opacity: 1;
      transition: 0.4s;
    }
  }
}

.arrow {
  height: 12px;
  transform: rotate(90deg);
  width: 12px;

  path {
    stroke: var(--extLight);
  }
}
