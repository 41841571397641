@import 'shared/assets/scss/helpers/typography';

.wrap {
  align-items: flex-start;
  background: var(--extGray1);
  border: 1px solid var(--extGray3);
  border-radius: 8px;
  color: var(--extBlack);
  display: flex;
  padding: 16px;

  .icon {
    margin-right: 16px;
    margin-top: 1.5px;

    img {
      height: 18px;
      min-width: 18px;
      width: 18px;
    }
  }

  .title {
    @include r1-b;
    margin-bottom: 8px;
  }

  .message {
    @include r2;
    word-break: break-word;
  }
}
