@import 'shared/assets/scss/helpers/typography';

.wrap {
  margin: 8px 0 0;
  
  .title {
    @include h6;
    margin-bottom: 8px;
  }
}
