.wrap {
  overflow: hidden;
  position: relative;
}

.skeleton {
  height: 100%;
  position: absolute;
  width: 100%;
}
