@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  margin-top: 16px;

  .arrow {
    margin-left: 4px;
    transform: rotate(180deg);
    transition: 0.3s;

    &.open {
      transform: rotate(0deg);
    }
  }

  .risks {
    opacity: 0;
    overflow: hidden;
    transition: 0.3s;

    .items {
      .risk {
        @include d-flex(center, space-between);
        padding: 16px;
        position: relative;
        transition: 0.3s;
        width: 100%;

        &::after {
          background-color: var(--extGray6);
          bottom: 0;
          content: '';
          height: 0.5px;
          left: calc(48px + 16px);
          position: absolute;
          right: 16px;
        }

        &:last-child {
          &::after {
            left: 16px;
            right: 16px;
          }
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          transition: 0.2s;
        }

        .description {
          @include r3(var(--extGray3));
          margin-top: 2px;
          text-align: left;
        }

        .main {
          @include d-flex(center, flex-start);

          .image {
            margin-right: 8px;
          }

          .iconImage {
            height: 10px;
            margin-left: 4px;
            width: 10px;
          }
        }

        .name {
          @include r1-b(var(--extLight));
          align-items: center;
          display: flex;

          span {
            @include ellipsis-text(1, 198px);
          }
        }

        .verification {
          margin-top: 2px;
        }
      }
    }

    .risksWrap {
      @include d-flex(center, flex-end);

      .count {
        @include r3(var(--extLight));
        margin-right: 8px;
      }
    }

    .arrowRisk {
      height: 12px;
      transform: rotate(90deg);
      width: 12px;

      path {
        stroke: var(--extLight);
      }
    }

    .traceBtn {
      @include r1(var(--extLight));
      @include d-flex(center, space-between);
      padding: 16px;
      position: relative;
      transition: 0.2s;
      width: 100%;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        transition: 0.2s;
      }

      .iconExternal {
        height: 10px;
        width: 10px;

        path {
          stroke: var(--extLight);
        }
      }
    }
  }

  .collapsedContent {
    align-items: center;
    display: flex;
    gap: 8px;
    opacity: 1;
    padding: 0 16px;
    transition: 0.2s;

    .collapsedImage {
      border: 1.5px solid var(--extGray6);

      &:not(:first-child) {
        margin-left: -10px;
      }
    }

    .images {
      align-items: center;
      display: flex;
    }

    .title {
      @include r3(var(--extLight));
    }
  }

  &.open {
    margin-top: 0;
  
    .risks {
      opacity: 1;
      transition: 0.3s;
    }

    .collapsedContent {
      opacity: 0;
      transition: 0.2s;
    }
  }
}
