@import 'shared/assets/scss/helpers/mixins';

.wrap {
  @include r1(var(--extGray5));
  background: var(--extGray1);
  border-radius: 4px;
  border-radius: 12px;
  padding: 6px 12px;
  position: relative;
  width: fit-content;

  &.isBlack {
    background-color: var(--extBlack);
    color: var(--extLight);

    path {
      fill: var(--extBlack);
    }
  }
  
  &.isDark {
    background-color: var(--extDark) !important;
    color: var(--extLight);

    path {
      fill: var(--extDark) !important;
    }
  }

  &.isDisabled {
    color: var(--extGray3);
  }

  .tail {
    left: 0;
    position: absolute;
    top: -6px;
  }

  .main {
    align-items: center;
    display: flex;
    gap: 8px;
  }
}

@include dark-theme {
  .wrap {
    background: var(--extGray7);
    color: var(--extLight);

    .tail {
      path {
        fill: var(--extGray7);
      }
    }
  }
}
