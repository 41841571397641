@import './helpers/theme';
@import './helpers/fonts';
@import './helpers/typography';
@import './helpers/mixins';
@import './helpers/variables';

:root {
  --max-z-index-value: 2147483647;
}

.web3-antivirus {
  @import './vendors/normalize';

  &::-webkit-scrollbar {
    @include scroll();
    width: 0;
  }

  body {
    height: 100%;
    width: $extensionWidth;
  }

  // this line is needed for this font to be pulled up to https://superrare.com/
  body,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  span,
  input,
  textarea {
    font-family: 'Inter', sans-serif;
  }

  button {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    min-width: auto;
    outline: 0;
    padding: 0;

    &:focus {
      outline: 0;
    }
  }

  p {
    margin: 0;
  }

  ul {
    padding: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    appearance: none;
  }

  h1 {
    @include h1;
    margin: 0;
  }

  h2 {
    @include h2;
    margin: 0;
  }

  h3 {
    @include h3;
    margin: 0;
  }

  h4 {
    @include h4;
    margin: 0;
  }

  h6 {
    @include h6;
    margin: 0;
  }

  svg {
    vertical-align: initial;
  }
}

.ui-alerts {
  padding: 0;
}
