@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  align-items: center;
  background: transparent;
  display: flex;
  width: fit-content;

  .address {
    @include r2-b;
    text-decoration: none;
  }

  .ethIcon {
    height: 16px;
    width: 16px;
  }

  .ethIcon {
    margin-right: 4px;
  }

  .copyBtn {
    background: none;
    border: 0;
    cursor: pointer;
    height: 14px;
    margin-left: 4px;
    outline: none;

    &:hover {
      .copyIcon {
        path {
          stroke: var(--extBlack);
        }
      }
    }
  }

  .verifiedIcon {
    height: 14px;
    width: 14px;
  }
}
