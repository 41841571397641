@import 'shared/assets/scss/helpers/typography';

.wrap {
  align-items: center;
  background: var(--extWhite);
  border: 1px solid var(--extBlack);
  border-radius: 6px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  padding: 3px 6px;
  width: fit-content;

  .price {
    @include r1-b();
    border-radius: 4px;
    color: var(--extBlack);
  }
}

.popup {
  max-width: 246px !important;

  .popupContent {
    font-weight: 400;

    .infoText,
    .percentText {
      @include r3;
      color: var(--extBlack);
    }

    .percentText {
      font-weight: 700 !important;
    }

    .infoText {
      &:first-child {
        margin-bottom: 6px;
      }

      &:last-child {
        margin-top: 6px;
      }
    }
  }
}
