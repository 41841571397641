@import "shared/assets/scss/helpers/typography";
@import "shared/assets/scss/helpers/mixins";

.wrap {
  height: 150px;
}

.loader {
  height: 100%;
  width: 100%;
}

.tooltip {
  @include r3;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 7px;

  .date {
    color: var(--extGray5);
  }
}

.chartContainer {
  height: 150px;
}

.wrap {
  :global {
    .recharts-surface {
      overflow: visible !important;
    }

    .recharts-yAxis {
      .recharts-cartesian-axis-tick {
        &:first-child {
          display: none;
        }
      }

      .recharts-cartesian-axis-tick-line {
        display: none;
      }

      .recharts-cartesian-axis-tick-value {
        transform: translateY(-3px);
      }
    }

    .recharts-cartesian-axis-tick-value {
      color: var(--extGray5);
      font-size: 9px;
      line-height: 11px;
    }

    .recharts-cartesian-grid-horizontal {
      line {
        &:first-child {
          transform: translate(10%, 6px) scaleX(0.8);
        }
      }
    }

    .recharts-xAxis .recharts-cartesian-axis-line {
      stroke: rgba(0, 0, 0, 0.2);
      stroke-width: 0.5;
    }
  }
}

.rightYAxis {
  text {
    fill: var(--extPrimary3);
  }
}

@include dark-theme {
  .tooltip {
    background-color: var(--extGray7);
  }

  .wrap {
    :global {
      .recharts-xAxis .recharts-cartesian-axis-line {
        stroke: var(--extGray7);
      }

      .recharts-cartesian-axis-tick-value {
        color: var(--extGray4);
      }
    }
  }
}
