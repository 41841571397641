@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: radial-gradient(70% 65.01% at 128% 23.6%,
  #A4A4A4 -15.34%,
  #202124 98%),
    #202124;
  border-radius: 16px;
  margin: 0 16px 8px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.withBanner {
    background: none;

    .container {
      background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    }
  }

  .banner {
    background-color: var(--extBlack);
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  .container {
    align-items: flex-start;
    border-radius: 16px;
    display: flex;
    gap: 16px;
    padding: 16px;
    position: relative;

    .nameWrap {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 8px;

      .name {
        @include h4(var(--extLight));
        @include ellipsis-text(1, 170px);
      }

      .symbol {
        @include r3(var(--extLight));
        background: rgba(255, 255, 255, 0.15);
        border-radius: 4px;
        padding: 2px 6px;
      }

      .icon {
        height: 12px;
        width: 12px;
      }
    }

    .labels {
      @include r3(var(--extGray3));
      align-items: center;
      column-gap: 4px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1px;

      .label {
        &:not(:last-child) {
          &::after {
            color: var(--extGray5);
            content: '•';
            margin-left: 4px;
          }
        }
      }
    }

    .additionalInfo {
      align-items: center;
      display: flex;
      gap: 4px;
      margin-top: 10px;

      .item {
        @include r3(var(--extGray3));
        align-items: center;
        border: 0.5px solid var(--extGray6);
        border-radius: 4px;
        display: flex;
        gap: 4px;
        padding: 2px 5px;

        .value {
          color: var(--extLight);
        }
      }
    }
  }
}

@include dark-theme {
  .wrap {
    background: radial-gradient(70% 65.01% at 128% 23.6%,
    #666 -15.34%,
    #000 98%),
      #000;
  }
}
