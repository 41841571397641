@import 'shared/assets/scss/helpers/mixins';
@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/variables';

.wrap {
  background: var(--extGray1);
  padding: 8px 16px;

  .footerText {
    @include h1();
    color: var(--extBlack);
    margin-bottom: 16px;
    margin-top: 0;
    text-align: center;
  }
}

.buttons {
  @include d-flex(center, space-between);
  gap: 8px;

  .button {
    &:first-child {
      padding: 10px 56px;

      @include media-breakpoint-down($extensionWidth) {
        padding: 10px 40px;
      }
    }

    &:last-child {
      flex-grow: 1;
    }

    &button {
      @include button-s-xs;
      @include d-flex();
      box-sizing: border-box;
      height: 40px;
    }

    .walletImage {
      height: 20px;
      margin-left: 8px;
      width: 20px;
    }

    svg {
      height: 20px;
      margin-left: 8px;
      width: 20px;

      path {
        &:first-child {
          stroke: var(--extWhite);
        }

        &:last-child {
          fill: var(--extWhite);
        }
      }
    }


    &.withRisk {
      path {
        &:first-child {
          stroke: var(--extBlack);
        }

        &:last-child {
          fill: var(--extBlack);
        }
      }
    }
  }
}

@include dark-theme {
  .wrap {
    background-color: var(--extWhite);
    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.5);
  }
}
