@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px;

  .socials {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .noInfo {
    @include r3(var(--extGray5));
    margin-top: 8px;
  }

  .title {
    @include h6;
    margin-bottom: 8px;
  }

  .emptySocials {
    svg {
      path {
        fill: var(--extGray1);

        &:not(:first-child) {
          fill: var(--extGray3);
        }
      }
    }
  }

  a {
    height: 24px;

    svg {
      height: 24px;
      width: 24px;
    }

    &:hover {
      opacity: 0.6;
      transition: 0.2s;
    }
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);

    .emptySocials {
      svg {
        path {
          fill: #666;
  
          &:not(:first-child) {
            fill: var(--extWhite);
          }
        }
      }
    }
  }
}
