@import 'shared/assets/scss/helpers/typography';

.wrap {
  @include r3;
  align-items: center;
  background: var(--extWhite);
  border: 0.5px solid var(--extGray2);
  border-radius: 4px;
  column-gap: 4px;
  display: flex;
  justify-content: space-between;
  padding: 2px 5px;

  .name {
    color: var(--extGray5);
  }
}
