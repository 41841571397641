@import 'shared/assets/scss/helpers/typography';

.wrap {
  @include r3(var(--extGray3));
  align-items: center;
  display: flex;
  gap: 4px;

  .link {
    color: var(--extGray3);
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
      transition: 0.2s;
    }
  }
}
