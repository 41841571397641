@import 'shared/assets/scss/helpers/typography';

.wrap {
  overflow: hidden;

  .risk {
    align-items: center;
    display: flex;
    padding: 8px 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .icon {
      background-color: var(--extPrimary);
      border-radius: 50%;
      height: 8px;
      margin-bottom: 0;
      margin-right: 6px;
      width: 8px;

      &.hasRisk {
        background-color: var(--extError);
      }
    }

    .name {
      @include r2;
    }
  }
}
