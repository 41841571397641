@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrapper {
  @include d-flex(center, space-between);
  background-color: var(--extWhite);
  left: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  .main {
    @include d-flex(center, flex-start);
  }

  .icon {
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }

  .title {
    @include h1(var(--extWhite));
    font-size: 24px;
  }

  &.low {
    background: var(--extPrimary);
  }

  &.middle {
    background: var(--extWarning);

  }

  &.critical {
    background: var(--extError);
  }
}
