@import 'shared/assets/scss/helpers/typography';

.wrap {
  .usdPrice {
    @include h3-d;
  }

  .ethPrice {
    @include r2(var(--extGray5));
  }
}
