@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extGray2);
  border-radius: 24px;
  color: var(--extBlack);
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-left: 4px;
  padding: 2px 4.5px;
}

@include dark-theme {
  .wrap {
    background-color: var(--extGray7);
  }
}
