@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  align-items: center;
  background-color: var(--extWhite);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  .leftContent {
    align-items: center;
    display: flex;
  }

  .btn {
    height: 24px;

    svg {
      path {
        stroke: var(--extBlack);
      }
    }

    &:hover {
      transition: 0.2s;

      svg {
        path {
          stroke: var(--extGray6);
        }
      }
    }
  }

  .text {
    @include h6;
    margin-left: 16px;
    text-align: left;
  }
}
