@import 'shared/assets/scss/helpers/typography';

.wrap {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 24px;

  .badges {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .title {
    @include r2;
    color: var(--extGray5);
  }
}

.wrap,
.popupBadges {
  .badge,
  .count {
    @include r2-b;
    background: var(--extGray2);
    border-radius: 6px;
    padding: 3px 6px;
  }

  .badge {
    text-transform: capitalize;
    width: fit-content;
  }
}

.popupBadges {
  .badge {
    display: block;
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
