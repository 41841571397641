@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  padding: 6px 0;

  &:not(:first-child) {
    border-top: 0.5px solid var(--extGray2);
  }

  &.withRisk {
    .name {
      @include r2-b(var(--extError));
      align-items: center;
      display: flex;
      gap: 4px;

      .icon {
        height: 12px;
        width: 12px;
      }
    }
  }

  .main,
  .detects {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .detects {
    gap: 16px;
  }

  .name {
    @include r2;
  }

  .description {
    @include r3(var(--extGray5));
    opacity: 0;
    transition: 0.2s;
  }

  &.isOpen {
    .description {
      margin-bottom: 2px;
      margin-top: 8px;
      opacity: 1;
      transition: 0.2s;
    }
  }
}

.detect {
  align-items: center;
  color: var(--extGray5);
  display: flex;
  font-size: 9px;
  font-weight: 400;
  gap: 4px;
  line-height: 16px;

  .icon {
    border: 1px solid var(--extGray3);
    border-radius: 50%;
    height: 6px;
    width: 6px;

    &.checked {
      background-color: var(--extBlack);
      border: 1px solid var(--extBlack);
    }
  }
}

.infoPopupTrigger {
  path {
    stroke: var(--extGray3);
  }
}

@include dark-theme {
  .infoPopupTrigger {
    path {
      stroke: var(--extGray6);
    }
  }

  .wrap {
    &:not(:first-child) {
      border-top: 0.5px solid var(--extGray7);
    }
  }
}
