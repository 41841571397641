@import 'shared/assets/scss/helpers/mixins';

.white {
  &:global(.ui.popup) {
    background-color: var(--extWhite);
    border: 0;
    border-radius: 8px;
    box-shadow: none;
    color: var(--extBlack);
    filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.12));
    font-size: 12px;
    line-height: 16px;
    max-width: 220px;
    padding: 12px;

    &::before {
      background: var(--extWhite) !important;
      border: 0;
      box-shadow: none !important;
    }
  }
}

.diff {
  &:global(.ui.popup) {
    background-color: var(--ext20);
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--ext12);
    font-size: 12px;
    max-width: 220px;
    padding: 12px;
    width: 181px;

    &::before {
      background: var(--ext20) !important;
    }
  }
}

.tooltip {
  &:global(.ui.popup) {
    background-color: var(--ext12);
    border-radius: 8px;
    color: var(--ext21);
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    max-width: 220px;
    opacity: 0.8;
    padding: 5px 10px;

    &::before {
      background: var(--ext12) !important;
    }
  }
}

@include dark-theme {
  .white {
    &:global(.ui.popup) {
      background-color: var(--extGray7);

      &::before {
        background: var(--extGray7) !important;
      }
    }
  }
}
