@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.alert {
  margin-top: 12px;
}

.header {
  margin-bottom: 8px;
}

.wrap {
  background-color: var(--extWhite);
  padding: 0 16px 16px;
}

.block {
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 12px 16px 16px;

  .title {
    @include r3(var(--extGray5));
    margin-bottom: 8px;
  }
}

@include dark-theme {
  .wrap {
    .block {
      border-color: var(--extGray7);
    }
  }
}
