@import 'shared/assets/scss/helpers/mixins';

.wrap {
  .loader {
    animation: spin 1.2s infinite linear;
    background: conic-gradient(from 90deg at 50% 50%,
    rgba(39, 174, 96, 0) 0deg,
    rgba(0, 0, 0, 0) 0.04deg,
    #000 360deg);
    border-radius: 50%;
    height: 16px;
    position: relative;
    width: 16px;

    .mask {
      background-color: var(--extWhite);
      border-radius: 50%;
      bottom: 1px;
      left: 1px;
      position: absolute;
      right: 1px;
      top: 1px;
      z-index: 1;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@include dark-theme {
  .wrap {
    .loader {
      background: conic-gradient(from 90deg at 50% 50%,
      rgba(39, 174, 96, 0) 0deg,
      rgba(0, 0, 0, 0) 0.04deg,
      #FFF 360deg);
    }
  }
}
