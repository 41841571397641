@import 'shared/assets/scss/helpers/typography';

.emptyWrap {
  @include r2(var(--extGray5));
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}

.values {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.value {
  align-items: center;
  border: 1px solid var(--extGray2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px;

  &.price {
    margin-bottom: 8px;
  }
}

.firstLabel,
.secondLabel {
  @include r3();
  color: var(--extGray5);
}

.firstCell,
.secondCell {
  @include r1-b();

  .infinity {
    color: var(--extError);
    font-size: 17px;
  }
}

.secondLabel {
  align-items: center;
  display: flex;
  gap: 4px;
}

.percent {
  color: var(--extGray5);
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.minting {
  margin-top: 16px;

  .title {
    @include r2(var(--extGray4));
    margin-bottom: 2px;
  }

  .values {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .countText {
    color: var(--extBlack);
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }

  .progressBar {
    background-color: var(--extGray2);
    border-radius: 4px;
    height: 4px;
    position: relative;

    .progress {
      background-color: var(--extPrimary);
      border-radius: 4px;
      height: 4px;
    }
  }
}

.popup {
  max-width: 240px !important;
}

.wrapper > div {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
