.alertContainer {
  position: relative;

  width: 400px;

  &:first-child {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}
