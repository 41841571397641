@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background-color: var(--extWhite);

  .header {
    display: flex;

    .tab {
      @include s1;
      align-items: center;
      border-bottom: 2px solid var(--extWhite);
      color: var(--extGray4);
      display: flex;
      padding: 8px 16px;
      text-transform: none;
      transition: 0.3s;

      &:hover {
        color: var(--extBlack);
      }

      &.active {
        border-color: var(--extBlack);
        color: var(--extBlack);
      }

      .count {
        color: var(--extGray4);
        margin-left: 2px;
      }

      .risksCount {
        background: rgba(228, 92, 81, 0.1);
        border-radius: 24px;
        color: var(--extError);
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        margin-left: 4px;
        padding: 0 6px 1px;
        width: max-content;
      }

      .icon {
        height: 12px;
        margin-left: 4px;
        width: 12px;
      }
    }
  }

  .body {
    padding: 16px;
  }
}

.headerWrap {
  border-bottom: 1px solid var(--extGray3);
  overflow: auto;
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}

@include dark-theme {
  .headerWrap {
    border-bottom-color: var(--extGray7);
  }
}
