@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px 16px;

  .top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;

    &:hover {
      .arrowIcon {
        path {
          fill: var(--extBlack);
        }
      }
    }

    .title {
      @include r3;
    }

    .arrowIcon {
      height: 20px;
      width: 20px;
    }
  }

  .openBtn {
    align-items: center;
    color: var(--extBlack);
    display: flex;
    font-size: 11px;
    font-weight: 600;
    gap: 4px;
    line-height: 16px;
    margin-top: 8px;

    &:hover {
      color: var(--extGray5);
      transition: 0.2s;
    }

    .btnArrowIcon {
      height: 12px;
      transition: 0.2s;
      width: 12px;

      path {
        fill: var(--extBlack);
      }
    }
  }

  &.open {
    .arrowIcon {
      transform: rotate(180deg);
      transition: 0.2s;
    }

    .btnArrowIcon {
      transform: rotate(180deg);
      transition: 0.2s;
    }
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);
  }
}
