@import 'shared/assets/scss/helpers/typography';

.value {
  align-items: center;
  border: 1px solid var(--extGray2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px;

  &.sales {
    margin-bottom: 8px;
  }
}

.values {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
}

.firstLabel,
.secondLabel {
  @include r3();
  color: var(--extGray5);
}

.firstCell,
.secondCell {
  @include r1-b();

  .infinity {
    color: var(--extError);
    font-size: 17px;
  }

  .gray {
    color: var(--extGray5);
  }
}

.iconWrap {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.icon {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.warning {
  margin-bottom: 16px;
}

.wrapper > div {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
