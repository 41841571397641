@import 'shared/assets/scss/helpers/mixins';

$twitterBlue: #4A99E9;
$twitterBlue2: #2F84DA;
$twitterBlue3: #2B70B6;

.wrap {
  &.button {
    align-items: center;
    border-radius: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.15s ease-in-out;

    &.fullWidth {
      width: 100%;
    }

    .loaderWrap {
      align-items: center;
      background-color: inherit;
      border-radius: inherit;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }

    .loader {
      height: 20px;
      width: 20px;
    }

    &.sizeM {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      padding: 10px 22px;
    }

    &.sizeS {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding: 9.5px 19px;
    }

    &.sizeXS {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      max-height: 32px;
      padding: 5.5px 21px;

      .loader {
        height: 16px;
        width: 16px;
      }
    }

    &.primary {
      background-color: var(--extPrimary);
      border: 1px solid var(--extPrimary);
      color: var(--extLight);

      &:hover:not(:disabled):not(.loading) {
        background-color: var(--extPrimary2);
        border-color: var(--extPrimary2);
      }

      &:active:not(:disabled):not(.loading) {
        background-color: #00A867;
        border-color: #00A867;
      }

      &:disabled,
      &.loading {
        background-color: var(--extGray1);
        border-color: var(--extGray1);
        color: var(--extGray3);
      }
    }

    &.primary_black {
      background-color: var(--extBlack);
      border: 1px solid var(--extBlack);
      color: var(--extWhite);

      &:hover:not(:disabled):not(.loading) {
        background-color: var(--extGray6);
        border-color: var(--extGray6);
      }

      &:active:not(:disabled):not(.loading) {
        background-color: var(--extGray7);
        border-color: var(--extGray7);
      }

      &:disabled,
      &.loading {
        background-color: var(--extGray1);
        border-color: var(--extGray1);
        color: var(--extGray3);
      }
    }

    &.twitter {
      background: $twitterBlue;
      border: 1px solid $twitterBlue;
      color: var(--ext1);

      &:hover:not(:disabled):not(.loading) {
        background-color: $twitterBlue2;
        border-color: $twitterBlue2;
      }

      &:active:not(:disabled):not(.loading) {
        background-color: $twitterBlue3;
        border-color: $twitterBlue3;
      }

      &:disabled,
      .loading {
        background-color: var(--extGray2);
        border-color: var(--extGray2);
        color: var(--extGray3);

        .loaderMask {
          background-color: var(--extGray1);
        }

        svg {
          path {
            fill: var(--extGray3);
          }
        }
      }
    }

    &.tertiary {
      background: transparent;
      border: 1px solid transparent;
      color: var(--ext12);
      padding: 0;

      svg {
        path {
          transition: all 0.15s ease-in-out;
        }
      }

      &:hover:not(:disabled):not(.loading) {
        color: var(--ext2);

        svg {
          path {
            stroke: var(--ext2);
          }
        }
      }

      &:active:not(:disabled):not(.loading) {
        color: var(--extPrimary2);

        svg {
          path {
            stroke: var(--extPrimary2);
          }
        }
      }

      &:disabled,
      .loading {
        color: var(--extGray1);

        svg {
          path {
            stroke: var(--extGray3);
          }
        }

        .loaderMask {
          background-color: var(--extGray1);
        }
      }
    }

    &.secondary {
      background: var(--extWhite);
      border: 1px solid var(--extGray2);
      color: var(--extBlack);

      &:hover:not(:disabled):not(.loading) {
        background-color: var(--extGray1);
        border-color: var(--extGray3);
      }

      &:active:not(:disabled):not(.loading) {
        background-color: var(--extGray2);
        border-color: var(--extGray2);
      }

      &:disabled,
      .loading {
        border-color: var(--extGray2);
        color: var(--extGray3);

        .loaderMask {
          background-color: var(--extGray1);
        }
      }
    }

    &.destructive {
      background: var(--extError);
      border: 1px solid var(--extError);
      color: var(--extLight);

      &:hover:not(:disabled):not(.loading) {
        background-color: #D13F33;
        border-color: #D13F33;
      }

      &:active:not(:disabled):not(.loading) {
        background-color: #B6392F;
        border-color: #B6392F;
      }

      &.loading {
        background-color: var(--extGray1);
        border-color: var(--extGray1);

        .loaderMask {
          background-color: var(--extGray1);
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

@include dark-theme {
  .wrap {
    &.button {
      &.secondary {
        border: 1px solid var(--extLight);

        &:hover:not(:disabled):not(.loading) {
          background-color: var(--extWhite);
          border-color: var(--extGray3);
          color: var(--extGray3);
        }
      }
    }

    &.primary_black {
      border: 1px solid var(--extLight);

      &:hover:not(:disabled):not(.loading) {
        background-color: var(--extGray1);
        border-color: var(--extGray1);
        color: var(--extGray6);
      }

      .loaderMask {
        background-color: var(--extGray1);
      }

      .loader {
        background: conic-gradient(from 90deg at 50% 50%, rgba(39, 174, 96, 0) 0deg, rgba(0, 0, 0, 0) 0.04deg, #000 360deg);
      }
    }
  }
}
