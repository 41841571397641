@import 'shared/assets/scss/helpers/typography';

.wrap {
  @include r1;
  align-items: center;
  background: var(--extWhite);
  color: var(--extBlack);
  display: flex;
  gap: 4px;
  justify-content: space-between;
  margin-bottom: 12px;

  .info {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  .nameWrap {
    align-items: center;
    column-gap: 4px;
    display: flex;
  }

  .honeypotIcon {
    height: 10px;
    width: 10px;
  }
  
  .value {
    @include h6;
    max-width: 50%;
    text-align: right;
    word-break: break-all;
    
    .usdPrice {
      @include r3(var(--extGray5));
      margin-top: 2px;
    }
  }
}
