@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  .title {
    @include h4;
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 2px;

    .icon {
      height: 12px;
      width: 12px;
    }
  }

  .labels {
    @include r3(var(--extGray5));
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 1px;

    .verifiedIcon {
      height: 13px;
      width: 18px;
    }

    .label {
      &:not(:first-child) {
        &::before {
          color: var(--extGray3);
          content: '•';
          margin-right: 4px;
        }
      }
    }

    .link {
      @include link(var(--extGray5));
      @include r3(var(--extGray5));
    }
  }

  .mainInfo {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 16px;

    .item {
      .label {
        @include r3(var(--extGray5));
      }

      .value {
        @include r2-b;
      }
    }
  }

  .contractData {
    margin-top: 16px;

    .item {
      @include r3;
      align-items: center;
      border-top: 0.5px solid var(--extGray2);
      column-gap: 4px;
      display: flex;
      flex-wrap: wrap;
      padding: 6px 0;

      &.auditsWrap {
        display: block;

        .label {
          margin-right: 4px;
        }
      }

      .label {
        color: var(--extGray5);
      }

      &.addressWrap {
        justify-content: space-between;
      }

      .audits {
        .audit {
          @include link;
        }
      }
    }
  }

  &.withChart {
    .contractData {
      margin-top: 0;
    }
  }
}

@include dark-theme {
  .contractData {
    .item {
      border-top-color: var(--extGray7);
    }
  }
}
