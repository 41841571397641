@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  background-color: var(--extGray2);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  &.L {
    height: 80px;
    min-width: 80px;
    width: 80px;
  }

  &.M {
    height: 40px;
    min-width: 40px;
    width: 40px;
  }

  &.S {
    height: 20px;
    min-width: 20px;
    width: 20px;
  }

  &.round {
    border-radius: 50%;
  }
}

.image {
  &.hidden {
    display: none;
  }
}

.stub {
  align-items: center;
  color: var(--extBlack);
  display: flex;
  font-style: normal;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;

  &.round {
    border-radius: 50%;
  }

  &.L {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  &.M {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.75px;
    line-height: 30px;
  }

  &.S {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.75px;
    line-height: 12px;
  }
}

.skeletonContainer {
  font-size: 0;
  line-height: 0;
}

.skeleton {
  &.hidden {
    display: none;
  }
}

@include dark-theme {
  .wrap {
    background-color: var(--extGray6);
  }
}
