@import './typography';

@mixin ellipsis-text($rowsCount: 1, $maxWidth: 100%) {
  box-orient: vertical;
  -webkit-line-clamp: $rowsCount;
  max-width: $maxWidth;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

@mixin scroll($track: transparent, $thumb: var(--extGray3)) {
  &::-webkit-scrollbar {
    height: 8px;
    width: 6px !important;
  }

  &::-webkit-scrollbar-track {
    background: $track !important;
    border-radius: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb !important;
    border-radius: 4px !important;
  }

  &:has(scrollbar-width) {
    scrollbar-width: thin !important;
  }
}

@mixin link($color: var(--extBlack)) {
  @include r2;
  color: $color;
  transition: 0.3s;

  &:hover {
    color: var(--extGray6);
    text-decoration: underline;
  }
}

@mixin d-flex($ai: center, $jc: center) {
  align-items: $ai;
  display: flex;
  justify-content: $jc;
}

@mixin section-border() {
  background: var(--extWhite);
  border: 1px solid var(--extGray2);
  border-radius: 8px;
}

@mixin appearance-animation() {
  animation: 0.6s appearance;

  @keyframes appearance {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin spin-animation() {
  animation: spin 1.2s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @media (max-width: ($breakpoint)) {
    @content;
  }
}

@mixin dark-theme() {
  :global(.dark-ext) {
    @content;
  }
}

@mixin block() {
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px 16px;
}
