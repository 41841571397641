@import 'shared/assets/scss/helpers/mixins';

.info,
.data {
  align-items: center;
  display: flex;
}

.data {
  gap: 8px;
}

.info {
  gap: 4px;

  .gasIcon {
    height: 16px;
    width: 16px;
  }
}

@include dark-theme {
  .info {
    .gasIcon {
      path {
        fill: var(--extLight);
      }
    }
  }
}
