@import 'shared/assets/scss/helpers/typography';

.wrap {
  .names {
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 3px 0;

    .name {
      @include r2-b(var(--extError));
      border: 0.5px solid var(--extGray2);
      border-radius: 4px;
      padding: 3px 8px;
    }
  }

  .entities {
    .entity {
      &:not(:last-child) {
        border-bottom: 1px dashed var(--extGray2);
        margin-bottom: 16px;
        padding-bottom: 16px;
      }

      .top {
        align-items: center;
        display: flex;
        gap: 10px;
        margin-bottom: 8px;
      }

      .separator {
        background-color: var(--extGray3);
        height: 16px;
        width: 1px;
      }

      .contractName {
        @include r2(var(--extGray5));
      }
    }
  }

  .description {
    @include r2;
    color: var(--extGray5);
    margin-bottom: 8px;
  }

  .activity {
    @include r2;
    align-items: center;
    display: flex;
    gap: 4px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    .index {
      color: var(--extGray5);
    }

    .date {
      color: var(--extBlack);
    }

    .text {
      color: var(--extError);
    }
  }
}
