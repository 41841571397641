.contracts {
  align-items: center;
  display: flex;
  gap: 8px;

  .btn {
    position: relative;
    transition: 0.2s;

    &:hover,
    &.active {
      &::after {
        border: 1.5px solid var(--extBlack);
        border-radius: 50%;
        bottom: -3px;
        content: '';
        left: -3px;
        position: absolute;
        right: -3px;
        top: -3px;
        transition: 0.2s;
      }
    }
  }
}
