@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  border: 1px solid var(--extGray2);
  border-radius: 16px;
  padding: 12px 16px 16px;

  .title {
    @include r3(var(--extGray5));
    margin-bottom: 8px;
  }
}

@include dark-theme {
  .wrap {
    border-color: var(--extGray7);
  }
}
