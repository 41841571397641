@import 'shared/assets/scss/helpers/typography';

.wrap {
  @include r3(var(--extWhite));
  align-items: center;
  background-color: var(--extBlack);
  border-radius: 50%;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}
