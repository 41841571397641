@import 'shared/assets/scss/helpers/typography';
@import 'shared/assets/scss/helpers/mixins';

.wrap {
  .contractCard {
    margin-bottom: 8px;
  }

  .info {
    margin-bottom: 8px;

    &.relatedInfo {
      margin-top: 16px;
    }

    .title {
      @include h6;
      align-items: center;
      column-gap: 4px;
      display: flex;
      margin-bottom: 8px;
    }

    .description {
      @include r2(var(--extGray5));
    }
  }

  .traceBtn {
    @include r3;
    align-items: center;
    background: var(--extWhite);
    border: 1px solid var(--extGray2);
    border-radius: 16px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 20px 16px;
    transition: 0.2s;
    width: 100%;

    &:hover {
      background-color: var(--extGray1);
      transition: 0.2s;
    }

    .name {
      align-items: center;
      column-gap: 4px;
      display: flex;
    }

    .iconLink {
      height: 12px;
      width: 12px;
    }
  }
}

@include dark-theme {
  .wrap {
    .traceBtn {
      border-color: var(--extGray7);

      &:hover {
        background-color: var(--extGray7);
      }

      .iconLink {
        path {
          stroke: var(--extLight);
        }
      }

      .name {
        svg {
          path {
            fill: var(--extLight);
          }
        }
      }
    }
  }
}
