@import 'shared/assets/scss/helpers/mixins';
@import 'shared/assets/scss/helpers/variables';

.wrap {
  height: 16px;
  width: 16px;
}

.popup {
  @include media-breakpoint-down($mobileWidth) {
    max-width: 190px !important;
  }
}

.icon {
  cursor: pointer;
  transition: 0.3s;
}
