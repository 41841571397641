@import 'shared/assets/scss/helpers/variables';

@mixin body-xl($font-weight: 600) {
  font-size: 16px;
  font-weight: $font-weight;
  line-height: 20px;
}

@mixin body-l($font-weight: 400) {
  font-size: 13px;
  font-weight: $font-weight;
  line-height: 16px;
}

@mixin body-m($font-weight: 400) {
  font-size: 12px;
  font-weight: $font-weight;
  line-height: 15px;
}

@mixin body-s {
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
}

@mixin h1($color: var(--extBlack)) {
  color: $color;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 26px;
}

@mixin h2($color: var(--extBlack)) {
  color: $color;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

@mixin h3 ($color: var(--extBlack)) {
  color: $color;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
}

@mixin h4 ($color: var(--extBlack)) {
  color: $color;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 26px;
}

@mixin h5($color: var(--extBlack)) {
  color: $color;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@mixin h6($color: var(--extBlack)) {
  color: $color;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

@mixin r1($color: var(--extBlack)) {
  color: $color;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin r1-b($color: var(--extBlack)) {
  @include r1($color);
  font-weight: 700;
}

@mixin r2($color: var(--extBlack)) {
  color: $color;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@mixin r2-b($color: var(--extBlack), $fontWeight: 700) {
  @include r2($color);
  font-weight: $fontWeight;
}

@mixin r3($color: var(--extBlack)) {
  color: $color;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

@mixin r3-b($color: var(--extBlack)) {
  @include r3($color);
  color: $color;
  font-weight: 700;
}

@mixin button-m {
  color: var(--extBlack);
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

@mixin button-s-xs {
  color: var(--extBlack);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

@mixin h1-d($color: var(--extBlack)) {
  color: $color;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 46px;
}

@mixin h2-d {
  color: var(--extBlack);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 34px;
}

@mixin h3-d($color: var(--extBlack)) {
  color: $color;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 30px;
}

@mixin h4-d {
  color: var(--extBlack);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 24px;
}

@mixin h5-d {
  color: var(--extBlack);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 24px;
}

@mixin r1-d($color: var(--extGray5)) {
  color: $color;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin r2-d($color: var(--extGray5), $fontWeight: 400) {
  color: $color;
  font-size: 14px;
  font-weight: $fontWeight;
  line-height: 20px;
}

@mixin s1($color: var(--extBlack)) {
  color: $color;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
